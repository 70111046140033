import '../styles/accordion.scss';

import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

function FaqSection( { faqs = [{ question: '', answer: '' }] } ) {

  const [showArrow, setArrow] = useState( -1 );

  function clicked( e, i ){
    if( showArrow === i ){
      setArrow( -1 );
    } else {
      setArrow( i );
    }
  }
  return(
    <>
      <div className="faq-box">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="faq-box-head">
                Frequently Asked Questions
              </h2>
            </div>
          </div>

          <Accordion>
            <div className="row">
              <div className="col-md-6 col-12 ">
                <div className="row">
                  <div className="col-md-12 col-12">
                    {
                      faqs.slice( 0,4 ).map( ( { question, answer }, i ) => {
                        return(
                          <Card key={i}>
                            <Accordion.Toggle onClick={( e ) => clicked( e, i )} as={Card.Header} eventKey={i + 1}>
                              <div className="flexRow align-items-center justify-content-between">

                                <button className="btn btn-link">
                                  { question }
                                </button>

                                <img className={showArrow !== i ? 'select-box-icon' : 'select-box-icon rotate'} src='https://cdn.onlinewebfonts.com/svg/img_295694.svg'
                                  alt="Arrow Icon" aria-hidden="true" />

                              </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse  eventKey={i + 1}>
                              <Card.Body>
                                { answer }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      } )
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 ">
                <div className="row">
                  <div className="col-md-12 col-12">
                    {
                      faqs.slice( 4 ).map( ( { question, answer }, i ) => {
                        return(
                          <Card key={i + 4}>
                            <Accordion.Toggle as={Card.Header} onClick={( e ) => clicked( e, i + 4 )}eventKey={i + 5}>
                              <div className="flexRow align-items-center justify-content-between">

                                <button className="btn btn-link">
                                  { question }
                                </button>

                                <img className={showArrow !== i + 4 ? 'select-box-icon' : 'select-box-icon rotate'} src='https://cdn.onlinewebfonts.com/svg/img_295694.svg'
                                  alt="Arrow Icon" aria-hidden="true" />

                              </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey={i + 5}>
                              <Card.Body>
                                { answer }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      } )
                    }

                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default FaqSection;
